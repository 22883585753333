<template>
  <div>
    <div>
      <Swiper
        ref="banner"
        class="banner"
        :style="{ height: bannerH + 'px' }"
        :options="bannerOptions"
      >
        <SwiperSlide
          v-if="stars.length"
          class="banner-item banner-earth pc-wrap"
        >
          <div class="pc-content">
            <div class="earth-title-m">
              <span class="mr-10 mark">赋能</span>
              <span class="mr-10">链接</span>
              <span class="mr-10 mark">利他</span>
              <span>共享</span>
              <div class="earth-subtitle">品质新生活引领者</div>
            </div>

            <Earth
              ref="earth"
              class="earth-box"
              :boxWidth="boxWidth"
              :dots="stars"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          v-for="(item, index) in banner"
          :key="index"
          class="banner-item img"
          :style="{ 'background-image': 'url(' + item.pic + ')' }"
          @click.native="$jump(item.url)"
        ></SwiperSlide>
      </Swiper>
      <div v-if="bannerSize > 1" class="swiper-pagination">
        <span
          v-for="(item, index) in bannerSize"
          :key="index"
          class="swiper-pagination-item"
          :class="{ on: bannerIndex == index }"
        ></span>
      </div>
    </div>
    <div class="pc-wrap pt-container">
      <div class="pc-content">
        <div class="pt-row acea-row">
          <div class="pt-item flex-con">
            <div class="pt-num">200<i class="iconfont">&#xe633;</i></div>
            <div>联盟企业</div>
          </div>
          <div class="pt-item flex-con">
            <div class="pt-num">20000<i class="iconfont">&#xe633;</i></div>
            <div>服务会员</div>
          </div>
          <div class="pt-item flex-con">
            <div class="pt-num">1000000<i class="iconfont">&#xe633;</i></div>
            <div>服务社团</div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap scroll-tag">
      <div class="pc-content">
        <div class="title">信鸽平台</div>
        <div class="subtitle" style="margin-bottom: 20px">RUNZEHUI</div>
        <div>
          <img
            src="https://img.runzehui.net/2022/10/14/8f669202210141338476284.jpg"
            alt=""
          />
        </div>
        <div class="sz-menus acea-row row-wrap">
          <div
            v-for="(item, index) in szMenus"
            :key="index"
            class="sz-menu-item"
          >
            <img :src="item.img" alt="" />
            <div class="sz-menu-name">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="container pc-wrap scroll-tag">
      <div class="pc-content">
        <div class="title">信鸽商城</div>
        <div class="subtitle">RUNZEHUI</div>
        <div class="sc-box">
          <img
            src="https://img.runzehui.net/2022/08/19/ae0cb202208191021517995.png"
            alt=""
          />
          <div class="sc-wrap acea-row row-wrap">
            <div
              class="sc-item acea-row row-center row-middle"
              @click="$jump('/news/75')"
            >
              <div>
                <img src="@/assets/images/icon/icon-2-21.png" alt="" />
                <div>企业福采</div>
              </div>
            </div>
            <div
              class="sc-item light acea-row row-center row-middle"
              @click="$jump('/news/76')"
            >
              <div>
                <img src="@/assets/images/icon/icon-2-22.png" alt="" />
                <div>企业集采</div>
              </div>
            </div>
            <div
              class="sc-item light acea-row row-center row-middle"
              @click="$jump('/news/21')"
            >
              <div>
                <img src="@/assets/images/icon/icon-2-23.png" alt="" />
                <div>溯源商城</div>
              </div>
            </div>
            <div
              class="sc-item acea-row row-center row-middle"
              @click="$jump('/news/20')"
            >
              <div>
                <img src="@/assets/images/icon/icon-2-24.png" alt="" />
                <div>润品商城</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container pc-wrap scroll-tag">
      <div class="pc-content">
        <div class="title">集成服务</div>
        <div class="subtitle">RUNZEHUI</div>
        <div class="jc-wrap acea-row row-between row-wrap">
          <div
            v-for="(item, index) in jcArr"
            :key="index"
            class="jc-item"
            @click="$jump(item.url)"
          >
            <img :src="item.img" alt="" />
            <div>{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap scroll-tag">
      <div class="pc-content">
        <div class="title">专项服务</div>
        <div class="subtitle">RUNZEHUI</div>
        <div class="zx-box">
          <img
            src="@/assets/images/bg-3-01.jpg"
            alt=""
            @click="$jump('/news/53')"
          />
          <img
            src="@/assets/images/bg-3-02.jpg"
            alt=""
            @click="$jump('/news/52')"
          />
        </div>
      </div>
    </div>

    <div class="container pc-wrap scroll-tag">
      <div class="pc-content">
        <div class="title">数字企业联盟合作伙伴</div>
        <div class="subtitle">ALLIANCE PARTNER</div>
        <div class="">
          <img src="@/assets/images/bg-4.png" alt="" />
          <Swiper class="partner-swiper">
            <SwiperSlide v-for="(item, index) in partner" :key="index">
              <div class="partners acea-row row-wrap">
                <div
                  v-for="(it, i) in item"
                  :key="i"
                  class="partner-item"
                  :style="{ backgroundImage: 'url(' + it.img + ')' }"
                  @click="$jump(it.url)"
                ></div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div class="partner-tip">
            <i class="iconfont">&#xe60b;</i>
            <span>左右滑动查看更多</span>
            <i class="iconfont">&#xe60d;</i>
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap scroll-tag">
      <div class="pc-content">
        <div class="title">会员服务</div>
        <div class="subtitle">共享空间 共同富裕</div>
        <div class="zx-box">
          <img
            src="https://img.runzehui.net/2022/08/19/64b2d202208191510575018.png"
            alt=""
            @click="$jump('/news/5')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Earth from "@/components/earth/index";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.min.css";
import request from "@/utils/request";

export default {
  components: {
    Earth,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      bannerOptions: {
        on: {
          slideChangeTransitionStart: (e) => {
            this.bannerIndex = e.activeIndex;
          },
        },
      },
      bannerIndex: 0,
      bannerH: 500,
      boxWidth: 375,
      banner: [],
      stars: [],
      szMenus: [
        {
          img: "https://img.runzehui.net/2022/08/19/55076202208191002202298.png",
          title: "数字社交名片",
        },
        {
          img: "https://img.runzehui.net/2022/08/19/6a374202208191002201830.png",
          title: "数字官网",
        },
        {
          img: "https://img.runzehui.net/2022/08/19/db33c202208191002204480.png",
          title: "数字社团",
        },
        {
          img: "https://img.runzehui.net/2022/08/19/6693420220819100221389.png",
          title: "数字品牌",
        },
        {
          img: "https://img.runzehui.net/2022/08/19/b41dc202208191002213492.png",
          title: "数字活动",
        },
        {
          img: "https://img.runzehui.net/2022/08/19/111ea202208191002213146.png",
          title: "数字简历",
        },
        {
          img: "https://img.runzehui.net/2022/08/19/8f40a202208191002223459.png",
          title: "企业数字小程序",
        },
        {
          img: "https://img.runzehui.net/2022/08/19/fa3c3202208191002212890.png",
          title: "数字营销",
        },
      ],
      jcArr: [
        {
          img: "https://img.runzehui.net/2022/08/19/d45b1202208191345492043.png",
          title: "数字转型",
          url: "/news/46",
        },
        {
          img: "https://img.runzehui.net/2022/08/19/b20d4202208191345509914.png",
          title: "SAAS工具赋能",
          url: "/news/47",
        },
        {
          img: "https://img.runzehui.net/2022/08/19/431f6202208191345496449.png",
          title: "企业资源共享",
          url: "/news/48",
        },
        {
          img: "https://img.runzehui.net/2022/08/19/368d6202208191345493814.png",
          title: "商品溯源",
          url: "/news/49",
        },
        {
          img: "https://img.runzehui.net/2022/08/19/72f30202208191345501155.png",
          title: "数字化营销",
          url: "/news/51",
        },
        {
          img: "https://img.runzehui.net/2022/08/19/a6b3e202208191345497079.png",
          title: "对外开发系统",
          url: "/news/55",
        },
      ],
      partner: [],
    };
  },
  computed: {
    bannerSize() {
      let size = this.banner.length;
      if (this.stars && this.stars.length) {
        size++;
      }
      return size;
    },
  },
  created() {
    const dom = document.documentElement;
    const winW = dom.clientWidth;
    const winH = dom.clientHeight;
    this.bannerH = winH - 64;
    if (winW > winH) {
      this.boxWidth = Math.min(1100 - 200, winH);
    } else {
      this.boxWidth = winW;
    }

    request.get("index").then((res) => {
      let data = res.data;
      this.banner = data.home_banner;
      this.stars = data.home_star_ring;

      this.partner = this.splitArr(data.home_partner, 9);

      if (this.stars && this.stars.length) {
        this.onVisibilityChange();
      }
    });
  },
  mounted() {
    // request
    //   .get("wechat/config", { url: "https://www.runzehui.net/" })
    //   .then((res) => {
    //     let data = res.data;
    //     data.jsApiList = ["wx-open-launch-weapp"];
    //     data.openTagList = ["wx-open-launch-weapp"];
    //     console.log(data);
    //   });
    // setTimeout(() => {
    //   this.setScrollNavs();
    // });
  },
  methods: {
    onVisibilityChange() {
      document.addEventListener("visibilitychange", () => {
        if (document.hidden) {
          this.$refs.earth.earthPause();
        } else {
          this.$refs.earth.earthPlay();
        }
      });
    },
    splitArr(arr, N) {
      var result = [];
      for (var i = 0; i < arr.length; i += N) {
        result.push(arr.slice(i, i + N));
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  height: 100vh;
  background: #090d32;
  overflow: hidden;
}
.swiper-pagination {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  z-index: 1;

  .swiper-pagination-item {
    display: inline-block;
    width: 36px;
    height: 6px;
    background: #42404e;
    margin: 10px;

    &.on {
      background: #fff;
    }
  }
}

.banner-item {
  height: 100%;
  position: relative;

  &.img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.banner-earth {
  background: url("~@/assets/images/earth_bg.jpg") no-repeat;
  background-size: cover;
  background-position: -500px 0;
}

.earth-title-box {
  position: absolute;
  top: 15%;
  z-index: 1;
}

.earth-title-sub {
  font-size: 36px;
  color: #fff;
  letter-spacing: 4px;
  margin-bottom: 20px;
}

.earth-title-m {
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0;

  .mark {
    color: #f2ab3f;
  }

  .earth-subtitle {
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 6px;
    margin-top: 10px;
  }
}

.earth-box {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -10%;
  margin: auto;
}

.pt-container {
  padding: 20px 0 40px;
  background: #efefef;
}

.pt-row {
  .pt-item {
    font-size: 12px;
    text-align: center;

    .pt-num {
      display: inline-block;
      position: relative;
      font-size: 26px;
      color: #000054;
      font-weight: bold;

      .iconfont {
        font-size: 12px;
        position: absolute;
        top: -4px;
        right: -12px;
      }
    }
  }
}

.sz-menus {
  padding: 30px 0 15px;

  .sz-menu-item {
    width: 25%;
    margin-bottom: 20px;
    font-size: 12px;
    text-align: center;

    img {
      width: 54px;
      margin: auto;
      margin-bottom: 6px;
    }
  }
}

.sc-box {
  position: relative;

  .sc-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    .sc-item {
      width: 50%;
      height: 50%;
      font-size: 14px;
      color: #fff;
      text-align: center;
      background-color: rgba(0, 36, 171, 0.8);
      box-sizing: border-box;

      &.light {
        background-color: rgba(0, 113, 219, 0.9);
      }

      img {
        display: block;
        width: auto;
        height: 40px;
        margin: auto;
      }
    }
  }
}

.jc-wrap {
  .jc-item {
    width: 32%;
    margin-bottom: 10px;
    padding: 30px 0 24px;
    font-size: 12px;
    text-align: center;
    color: #fff;
    background-color: #0071db;
    border-radius: 6px;
    box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.1);

    img {
      width: auto;
      height: 44px;
      margin: auto;
      margin-bottom: 30px;
    }
  }
}

.zx-box {
  img {
    border-radius: 10px;
    margin-bottom: 10px;
  }
}

.partner-swiper {
  overflow: hidden;
}

.partners {
  margin: 0 5px;
  padding: 15px 0 5px;
  .partner-item {
    width: 31%;
    height: 44px;
    margin-bottom: 10px;
    margin-left: 3.5%;
    color: #fff;
    background-image: url();
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    border-radius: 6px;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);

    &:nth-child(3n-2) {
      margin-left: 0;
    }
  }
}
.partner-tip {
  font-size: 12px;
  color: #0034b8;
  text-align: center;
  letter-spacing: 1px;
  margin: 10px 0;

  .iconfont {
    margin: 0 6px;
    font-size: 13px;
  }
}
</style>